<template>
  <a-modal :class="$style.container" v-model="isShow" title="评论回复" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="回复内容" prop="content">
        <a-textarea v-model="form.content" placeholder="请输入"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  data () {
    return {
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      idContentEvaluate: undefined,
      form: {
        content: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        content: [{
          required: true,
          message: '回复内容不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  created () {

  },
  computed: {},
  methods: {
    init (item) {
      this.idContentEvaluate = item.id
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        content: ''
      }
    },
    // 提交保存.
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'shopReplyEvaluate'
          let query = {
            idContentEvaluate: this.idContentEvaluate
          }
          const res = await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          console.log('res', res)
          if (res) {
            this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '回复成功~' })
            this.isShow = false
            this.close()
            this.$emit('save')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
