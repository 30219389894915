<template>
  <a-form-model
    ref="form"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 19 }"
    :model="form"
  >
    <a-form-model-item label="自动审核显示:"  style="margin-left: 5px">
      <a-radio-group v-model="form.autoAuditSwitch">
        <a-radio :value=1>开启</a-radio>
        <a-radio :value=0>关闭</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="" :colon="false" style="margin-left: 5px">
      <span style="font-size: 12px;color:red;">自动审核开启后，评论通过敏感词过滤之后会自动显示在商品详情中</span>
    </a-form-model-item>
    <a-form-model-item
      :colon="false"
      label=" "
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 8 }"
      style="margin-left: 300px"
    >
      <a-space>
        <a-button @click="cancel"> 取消 </a-button>
        <a-button @click="determine" type="primary">确认</a-button
        >
      </a-space>
    </a-form-model-item>

    <br />
  </a-form-model>
</template>

<script>
export default {
  data () {
    return {
      btCount: 0,
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        autoAuditSwitch: ''
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    resetForm () {
      this.form = {
        autoAuditSwitch: ''
      }
    },
    cancel () {
      this.resetForm()
      this.$emit('cancel')
    },
    async init () {
      this.getEvaluatConfig()
    },
    async getEvaluatConfig () {
      const res = await this.$store.dispatch('http', {
        api: 'getEvaluatConfig'
      })
      if (res) {
        this.form.autoAuditSwitch = res.autoAuditSwitch
      }
    },
    // 提交保存
    async determine (e) {
      this.goComfirm()
    },
    async goComfirm (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = this.$utils.clone(this.form)
          let api = 'evaluateConfigEdit'
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', {
            message: '评价审核设置成功'
          })
          this.$emit('ok')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
